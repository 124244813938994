import React from 'react'
import { graphql } from 'gatsby'
import SEO from '../components/seo.js'
import get from 'lodash/get'
import Img from 'gatsby-image'
import Layout from '../components/layout'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import '../assets/css/hero.module.css'



class ContentPageTemplate extends React.Component {
  constructor(props) {
    super(props)
    
  }
  render() {    
    
    const post = get(this.props, 'data.contentfulPageContent')
    const siteTitle = get(this.props, 'data.site.siteMetadata.title')         
    var body = documentToReactComponents(JSON.parse(post.paragraphs.raw))

    return (
      <Layout location={this.props.location}>
        
        <SEO title={`${post.title} | ${siteTitle}`} keywords={["digital", "media", "social media", "website", "seo", "digital media"]} />          
        <div style={{ backgroundColor: '#ffffff'}}>
          <div className="page_wrapper" style={{minHeight:'60vh'}}>
              <h4 className='navbutton'><a href='/' >&#8249; Back </a></h4>
              <h1 className="section-headline">{post.heading}</h1>
              <p style={{ display: 'block'}}>{post.publishDate}</p>
              <div className="articlepage_heroimage_container">
                  <Img className="articlepage_heroimage" alt={post.title} fluid={post.heroImage.fluid} />
              </div>                        
              <div>{body}</div>
          </div>
        </div>          
      </Layout>
    )
  }
}

export default ContentPageTemplate

export const pageQuery = graphql`
  query ContentPageTemplateBySlug($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }    
    contentfulPageContent(slug: { eq: $slug }) {
        heading
        heroImage {
            fluid {
                src
                srcSet
            }
        }
        imageAlign
        paragraphs {
            raw
        }
        site
        siteSection
        slug
        id
    }
  }
`
